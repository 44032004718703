
require.register("https-browserify/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "https-browserify");
  (function() {
    var http = require('http');

var https = module.exports;

for (var key in http) {
    if (http.hasOwnProperty(key)) https[key] = http[key];
};

https.request = function (params, cb) {
    if (!params) params = {};
    params.scheme = 'https';
    params.protocol = 'https:';
    return http.request.call(this, params, cb);
}
  })();
});