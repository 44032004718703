
require.register("core-js/modules/es6.string.fontsize.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "core-js");
  (function() {
    'use strict';
// B.2.3.8 String.prototype.fontsize(size)
require('./_string-html')('fontsize', function (createHTML) {
  return function fontsize(size) {
    return createHTML(this, 'font', 'size', size);
  };
});
  })();
});