
require.register("core-js/modules/_date-to-primitive.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "core-js");
  (function() {
    'use strict';
var anObject = require('./_an-object');
var toPrimitive = require('./_to-primitive');
var NUMBER = 'number';

module.exports = function (hint) {
  if (hint !== 'string' && hint !== NUMBER && hint !== 'default') throw TypeError('Incorrect hint');
  return toPrimitive(anObject(this), hint != NUMBER);
};
  })();
});