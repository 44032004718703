
require.register("readable-stream/readable-browser.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {"util":false}, "readable-stream");
  (function() {
    exports = module.exports = require('./lib/_stream_readable.js');
exports.Stream = exports;
exports.Readable = exports;
exports.Writable = require('./lib/_stream_writable.js');
exports.Duplex = require('./lib/_stream_duplex.js');
exports.Transform = require('./lib/_stream_transform.js');
exports.PassThrough = require('./lib/_stream_passthrough.js');
  })();
});